<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'LoggedOut',
  async mounted() {
    await Auth.signOut();
    localStorage.clear();

    setTimeout(() => {
      this.$gtag.event('Toggle', {
        event_category: 'User',
        event_label: 'Logout',
      });
      this.$router.push({ name: 'ClientPlatform' }).catch(() => undefined);
    }, 2000);
  },
};
</script>

<template>
  <div>
    <h1>Goodbye</h1>
    <p>You have been successfully logged out.</p>
  </div>
</template>
